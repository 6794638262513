import axios, { AxiosRequestConfig } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { CreateQRActiveModel } from '../models';
import { qrActiveToPayload } from '../mappers';

const getUrl = ({ host }: { host: string }) => {
  return `${host}/qr/relate/assets`;
};

export const CreateQRActiveByAssetId = async({
  host,
  token,
  assetId,
  qrActiveData,
}: {
  host: string;
  token: string;
  qrActiveData: CreateQRActiveModel;
  assetId: string;
}): Promise<void> => {
  try {
    const url = getUrl({ host });
    const data = qrActiveToPayload({assetId, data: qrActiveData });
    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data },
    };

    await axios(options);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
