import QrAccessListStatusTagManager from 'components/molecules/QrAccessListStatusTagManager';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { CustomQrAccessModel } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/useQrAccessList';
import { QrAccessStatusEnum, QrAccessStatusText } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/utils';
import ContextualMenu from 'components/atoms/ContextualMenu';
import MenuAtom from 'components/atoms/MenuAtom';

export interface QrAccessListTableColumnsLiterals {
  qrTitle: string;
  validityTitle: string;
  statusTitle: string;
  actionsTitle: string;
  revokeButtonText: string;
}

interface Props {
  literals: QrAccessListTableColumnsLiterals,
  onRevoke: (uid: string) => void;
}

export const QrAccessListColumns = ({ literals, onRevoke }: Props
): AlfredTableColumn<CustomQrAccessModel>[] => {
  const { qrTitle, validityTitle, statusTitle, actionsTitle, revokeButtonText } = literals;

  return [
    {
      title: qrTitle,
      key: 'qr',
      width: '30%',
      render: (text, { qr }) => <span>{qr}</span>,
    },
    {
      title: validityTitle,
      key: 'validFrom',
      width: '30%',
      render: (text, { validity }) => <span>{validity}</span>,
    },
    {
      title: statusTitle,
      key: 'status',
      width: '30%',
      render: (text, { status }) => (
        <span>
          <QrAccessListStatusTagManager
            status={status as QrAccessStatusEnum}
            text={QrAccessStatusText[status as QrAccessStatusEnum]}
          />
        </span>
      ),
    },
    {
      title: actionsTitle,
      key: 'menu',
      width: '10%',
      render: (text, { uid, revokeActionDisabled }) => <ContextualMenu menu={(
        <MenuAtom
          items={[
            {
              key: 'revoke',
              label: revokeButtonText,
              onClick: () => onRevoke(uid),
              danger: true,
              disabled: revokeActionDisabled,
            },
          ]}
        />
      )} />,
    },
  ];
};
