import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import DangerPrimaryButton from 'components/atoms/Button/DangerPrimaryButton';
import './styles.scss';

export interface QrManualRevokeProps {
  title: string;
  description: string;
  cancelButtonText: string;
  acceptButtonText: string;
  onCancel: () => void;
  onAccept: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const QrManualRevokeModal: FC<QrManualRevokeProps> = ({
  title,
  description,
  cancelButtonText,
  acceptButtonText,
  onCancel,
  onAccept,
  isVisible,
  isLoading,
}) => {
  return (
    <ModalAtom
      className={'QrManualRevokeModal'}
      title={title}
      onCancel={onCancel}
      open={isVisible}
      destroyOnClose
      footer={[
        <DefaultButton
          disabled={isLoading}
          onClick={onCancel}
          title={cancelButtonText}
          key={cancelButtonText}
        />,
        <DangerPrimaryButton
          loading={isLoading}
          onClick={onAccept}
          title={acceptButtonText}
          key={acceptButtonText}
        />,
      ]}
    >
      <p className={'QrManualRevokeModal__description'}>{description}</p>
    </ModalAtom>
  );
};

export default QrManualRevokeModal;
