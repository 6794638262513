import { ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { InputRef } from 'antd';
import { ColumnType, FilterConfirmProps, SortOrder } from 'antd/lib/table/interface';
import { CustomDeviceModel } from 'constants/models';
import DeviceMenuExtra from 'components/organisms/DeviceMenuExtra';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import { ColumnSearchTranslationsType, DeviceDataIndex, getColumnSearchProps } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import LinkButton from 'components/atoms/Button/LinkButton';
import { DeviceConnectivityType } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useTabDevices';
import TextAtom from 'components/atoms/TextAtom';
import './styles.scss';

interface Props {
  gatewayId?: string;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  searchInput?: RefObject<InputRef>;
  connectivityFilters: { text: string, value: string }[];
  connectivityTagsManager: Record<DeviceConnectivityType, ReactNode>,
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onSelectSharedGateway: (gatewayUuid: string) => void;
}

interface SortPeripheralsByBatteryLevelProps {
  a: number | null,
  b: number | null,
  sortOrder?: SortOrder,
}

export const sortPeripherals = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortPeripheralsByBatteryLevel = ({ a, b, sortOrder }: SortPeripheralsByBatteryLevelProps) => {
  if (a === null) return sortOrder === 'ascend' ? 1 : -1;
  if (b === null) return sortOrder === 'ascend' ? -1 : 1;
  return a - b;
};

export const ColumnsPeripheralDevices = ({
  gatewayId,
  columnSearchTranslations,
  searchInput,
  connectivityFilters,
  connectivityTagsManager,
  onSearch,
  onReset,
  onSelectSharedGateway,
}: Props): ColumnType<CustomDeviceModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: 'Id',
      key: 'deviceId',
      width: '10%',
      sorter: (a, b) => Number(a.deviceId) - Number(b.deviceId),
      sortDirections: ['ascend', 'descend'],
      render: ({ deviceId, gatewayUuid }: CustomDeviceModel) => {
        return (
          <div className={'Columns__cell'}>
            <p className={'Columns__id'}>{deviceId.split(':')[0]}</p>
            {!!gatewayUuid && (<LinkButton className={'Columns__alias'} title={gatewayUuid || ''} onClick={() => onSelectSharedGateway(gatewayUuid)} />)}
          </div>);
      }
    },
    {
      title: `${t('type')}`,
      key: 'deviceType',
      width: '20%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      sorter: (a, b) => sortPeripherals(a.deviceType, b.deviceType),
      ...getColumnSearchProps({ dataIndex: 'deviceType', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: `${t('name')}`,
      key: 'deviceName',
      width: '25%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'deviceName', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: `${t('remaining_battery')}`,
      key: 'batteryLevel',
      width: '15%',
      render: ({ batteryLevel }: CustomDeviceModel) => {
        return batteryLevel !== null
          ? <span>{`${batteryLevel}%`}</span>
          : <span className='Columns__notReportedText'>{t('not_reported')}</span>
      },
      sorter: (a, b, sortOrder) => sortPeripheralsByBatteryLevel({ a: a.batteryLevel, b: b.batteryLevel, sortOrder }),
    },
    {
      title: `${t('_ASSET_DEVICE_COLUMN_CONNECTIVITY_TITLE')}`,
      key: 'connectivity',
      width: '15%',
      filters: connectivityFilters,
      onFilter: (value, { connectivity }) => !!connectivity && connectivity.includes(value as string),
      render: ({ connectivity }) => <span>{connectivityTagsManager[connectivity as DeviceConnectivityType]}</span>,
    },
    {
      title: `${t('_ASSET_DEVICE_COLUMN_UPDATED_AT_TITLE')}`,
      key: 'updatedAtLiteral',
      width: '10%',
      render: ({ updatedAt, updatedAtLiteral }) => <TextAtom disabled={!updatedAt}>{updatedAtLiteral}</TextAtom>,
    },
    {
      title: t('actions'),
      key: 'menuExtra',
      width: '5%',
      render: (values) => {
        return values.gatewayUuid ? <DeviceMenuExtra data={values} gatewayId={values.gatewayUuid} /> : null;
      },
    },
  ];
};
