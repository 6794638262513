import { RefObject } from 'react';
import { Button, Input, InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { COLORS } from 'constants/colors';
import { Icon } from 'components/atoms/Icons';
import { CustomDeviceModel } from 'constants/models';
import './styles.scss';

export type DeviceDataIndex = keyof CustomDeviceModel;

export interface ColumnSearchTranslationsType {
  searchButtonText: string;
  resetButtonText: string;
  closeButtonText: string;
  placeholder: string;
  notReportedText: string;
}

interface Props {
  dataIndex: DeviceDataIndex;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  searchInput?: RefObject<InputRef>;
  translations?: ColumnSearchTranslationsType;
}

export const getColumnSearchProps = ({ dataIndex, onSearch, onReset, searchInput, translations }: Props): AlfredTableColumn<CustomDeviceModel> => {

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const inputValue = selectedKeys?.length ? selectedKeys[0] as string : '';
      return (
        <div className={'ColumnSearch__dropdown'} onKeyDown={event => event.stopPropagation()}>
          <Input
            ref={searchInput}
            className={'ColumnSearch__dropdown-input'}
            placeholder={translations?.placeholder || ''}
            value={inputValue}
            onChange={(event) => setSelectedKeys(event.target.value ? [event.target.value] : [])}
            onPressEnter={() => onSearch && onSearch(selectedKeys as string[], confirm, dataIndex)}
          />
          <div className={'ColumnSearch__dropdown-buttons-wrapper'}>
            <Button type={'link'} size={'middle'} onClick={() => confirm()}>
              {translations?.closeButtonText || ''}
            </Button>
            <div>
              <Button
                className={'ColumnSearch__dropdown-button'}
                onClick={() => clearFilters && onReset && onReset(clearFilters, confirm, dataIndex)}
                size={'middle'}
              >
                {translations?.resetButtonText || ''}
              </Button>
              <Button
                type={'primary'}
                className={'ColumnSearch__dropdown-button'}
                onClick={() => onSearch && onSearch(selectedKeys as string[], confirm, dataIndex)}
                icon={<SearchOutlined />}
                size={'middle'}
              >
                {translations?.searchButtonText || ''}
              </Button>
            </div>
          </div>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => (
      <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
    ),
    onFilter: (value, record) => {
      const filteredRecord = record[dataIndex] as unknown as CustomDeviceModel;
      return filteredRecord.toString().toLowerCase().includes((value as string).toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      visible && setTimeout(() => searchInput?.current?.select(), 100);
    },
    render: (values: CustomDeviceModel) => !!values[dataIndex] ? <span>{values[dataIndex] as string}</span> : <span className={'ColumnSearch__notReportedText'}>{translations?.notReportedText}</span>,
  }
};
