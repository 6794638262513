import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { QrAccessModel } from 'core/domain/qr/models';
import { QrAccessListColumns, QrAccessListTableColumnsLiterals } from 'components/organisms/QrAccessListTableColumns';
import { UserPermissionType } from 'models/users.model';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { hasPermission } from 'services/permissions';
import { GetQrAccessListByAssetIdWithPagination } from 'core/domain/qr/repositories/getQrAccessListByAssetId';
import { DATE_FORMAT_SLASH } from 'constants/date';
import { useMessage } from 'hooks/useMessage';
import { QrAccessListTableProps } from '../QrAccessTableTemplate';
import { floorList } from './useManualQRAccess';
import { QrManualRevokeProps } from 'components/organisms/QrManualRevokeModal';
import { RevokeQrAccessById } from 'core/domain/qr/repositories/revokeQrAccessById';
import { QrAccessStatusEnum } from './utils';

export interface CustomQrAccessModel extends QrAccessModel {
  validity: string;
  revokeActionDisabled: boolean;
}

export const useQrAccessList = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const { assetId } = useParams<{ assetId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createQrAccessDrawerVisible, setCreateQrAccessDrawerVisible] = useState<boolean>(false);
  const [qrAccessList, setQrAccessList] = useState<CustomQrAccessModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentUid, setCurrentUid] = useState<string>('');
  const [isRevokeModalVisible, setIsRevokeModalVisible] = useState<boolean>(false);
  const [isRevoking, setIsRevoking] = useState<boolean>(false);

  const isQrAccessVisible =
    hasPermission([
      UserPermissionType.UPDATE_QR_CODE_ACCESS,
      UserPermissionType.READ_QR_CODE_ACCESS,
      UserPermissionType.CREATE_QR_CODE_ACCESS,
    ]) && floorList.includes(assetId);

  const QrAccessListTableTemplateTitle = t('_QR_ACCESS_LIST_TITLE');

  const createQrAccessButtonText = t('add');

  const qrAccessListTableColumnsLiterals: QrAccessListTableColumnsLiterals = {
    qrTitle: t('_QR_ACCESS_LIST_TITLE_COLUMN_QR'),
    validityTitle: t('_QR_ACCESS_LIST_TITLE_COLUMN_VALIDITY'),
    statusTitle: t('_QR_ACCESS_LIST_TITLE_COLUMN_STATUS'),
    actionsTitle: t('actions'),
    revokeButtonText: t('revoke'),
  };

  const qrAccessRevokeModalTitle = t('revoke');
  const qrAccessRevokeModalAcceptButtonText = t('revoke');
  const qrAccessRevokeModalCancelButtonText = t('cancel');
  const qrAccessRevokeModalDescription = t('_ACCESS_MANUAL_QR_REVOKE_MODAL_DESCRIPTION');

  const onOpenCreateQrAccess = () => {
    setCreateQrAccessDrawerVisible(true);
  };

  const onCloseCreateQrAccessDrawer = () => {
    setCreateQrAccessDrawerVisible(false);
  };

  const onChangeQrAccessListPagination = (page: number, newPageSize?: number) => {
    setCurrentPage(page);
    setPageSize(newPageSize!);
  };

  const onOpenRevokeModal = (uid: string) => {
    setCurrentUid(uid);
    setIsRevokeModalVisible(true);
  };

  const onCloseRevokeModal = () => {
    setCurrentUid('');
    setIsRevokeModalVisible(false);
  };

  const onConfirmRevokeQR = async () => {
    setIsRevoking(true);
    try {
      await RevokeQrAccessById({ token, host, uid: currentUid });
      setMessageSuccess({ description: t('_ACCESS_MANUAL_QR_ACCESS_REVOKE_SUCCESS_MESSAGE') });
      initializeQrAccessList();
      onCloseRevokeModal();
    } catch (error) {
      setMessageError({ description: t('_ACCESS_MANUAL_QR_ACCESS_REVOKE_ERROR_MESSAGE') });
    } finally {
      setIsRevoking(false);
    }
  };

  const qrAccessListColumns = QrAccessListColumns({
    literals: qrAccessListTableColumnsLiterals,
    onRevoke: onOpenRevokeModal,
  });

  const transformQrAccess = (qrAccess: QrAccessModel[]): CustomQrAccessModel[] => {
    return qrAccess.map((access) => {
      const validFromDate = moment.unix(access.validFrom).format(DATE_FORMAT_SLASH);
      const validUntilDate = moment.unix(access.validUntil).format(DATE_FORMAT_SLASH);
      const validity = `${validFromDate} - ${validUntilDate}`;
      const revokeActionDisabled = access.status === QrAccessStatusEnum.REVOKED || access.status === QrAccessStatusEnum.EXPIRED;

      return { ...access, validity, revokeActionDisabled };
    });
  };

  const resetPageAndSizeValues = () => {
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
    setPageSize(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  };

  const initializeQrAccessList = async () => {
    currentPage === Number(TABLE_PAGINATION_PAGE_DEFAULT) && pageSize === Number(TABLE_PAGINATION_SIZE_DEFAULT)
      ? getQrAccessListByAssetId({ page: Number(TABLE_PAGINATION_PAGE_DEFAULT), size: Number(TABLE_PAGINATION_SIZE_DEFAULT) })
      : resetPageAndSizeValues();
  };

  const getQrAccessListByAssetId = async ({ page, size }: { page: number; size: number }) => {
    setIsLoading(true);
    try {
      const { data, meta } = await GetQrAccessListByAssetIdWithPagination({ token, host, assetId, page, size });
      const transformedQrAccessList = transformQrAccess(data);
      setQrAccessList(transformedQrAccessList);
      setTotalPages(meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_QR_ACCESS_LIST_MESSAGE_ERROR') });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQrAccessListByAssetId({
      page: currentPage,
      size: pageSize || Number(TABLE_PAGINATION_SIZE_DEFAULT),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const qrAccessTableTemplateStates: QrAccessListTableProps = {
    QrAccessListTableTemplateTitle,
    createQrAccessButtonText,
    qrAccessList,
    qrAccessListColumns,
    qrAccessListCurrentPage: currentPage,
    qrAccessListPageSize: pageSize,
    qrAccessListTotalPages: totalPages,
    onChangeQrAccessListPagination,
    onOpenCreateQrAccess,
    loading: isLoading,
    isVisible: isQrAccessVisible,
  };

  const qrAccessRevokeModalStates: QrManualRevokeProps = {
    title: qrAccessRevokeModalTitle,
    acceptButtonText: qrAccessRevokeModalAcceptButtonText,
    cancelButtonText: qrAccessRevokeModalCancelButtonText,
    description: qrAccessRevokeModalDescription,
    isLoading: isRevoking,
    isVisible: isRevokeModalVisible,
    onAccept: onConfirmRevokeQR,
    onCancel: onCloseRevokeModal,
  };

  return {
    qrAccessTableTemplateStates,
    qrAccessRevokeModalStates,
    onCloseCreateQrAccessDrawer,
    initializeQrAccessList,
    createQrAccessDrawerVisible,
  };
};
