import axios, { AxiosRequestConfig } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, uid }: { host: string; uid: string }) => {
  return `${host}/qr/access/${uid}/revoke`;
};

export const RevokeQrAccessById = async ({ uid, host, token }: { uid: string; host: string; token: string }) => {
  const url = getUrl({ host, uid });

  try {
    const options: AxiosRequestConfig = {
      url,
      method: 'PUT',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };
    await axios(options);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
