import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputRef, TableColumnsType } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { DeviceModel, DeviceSensorModel } from 'core/domain/devices/models';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import {
  ExpandedPeripheralDevicesColumns,
  ExpandedPeripheralDevicesTranslations,
} from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import { getSharedGatewayPathByGatewayId } from 'components/pages/App/routes/projects/config';
import { DeviceConnectivityType } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useTabDevices';
import { DATE_TIME_FORMAT_REVERSE_SLASH } from 'constants/date';
import { CustomDeviceModel } from 'constants/models';
import { ColumnsPeripheralDevices } from '../ColumnsPeripheralDevices';
import TagError from 'components/atoms/tags/TagError';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagDefault from 'components/atoms/tags/TagDefault';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const history = useHistory();
  const { setMessageError } = useMessage();
  const { projectId } = useParams<{ projectId: string }>();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<CustomDeviceModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const searchInput = useRef<InputRef>(null);

  const peripheralsTitle: string = t('_PROJECT_PERIPHERAL_SHARED_DEVICES_TITLE');

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const onSelectSharedGateway = (gatewayUuid: string) => {
    history.push(getSharedGatewayPathByGatewayId({ projectId, gatewayId: gatewayUuid }));
  };

  const connectivityLiteralsManager = {
    [DeviceConnectivityType.OFFLINE]: t('offline'),
    [DeviceConnectivityType.ONLINE]: t('online'),
    [DeviceConnectivityType.UNKNOWN]: t('unknown'),
  };

  const connectivityTagsManager = {
    [DeviceConnectivityType.OFFLINE]: TagError({ text: t('offline') }),
    [DeviceConnectivityType.ONLINE]: TagSuccess({ text: t('online') }),
    [DeviceConnectivityType.UNKNOWN]: TagDefault({ text: t('unknown') }),
  };

  const connectivityCategoryTypes: string[] = Object.values(DeviceConnectivityType);

  const connectivityFilters: { text: string; value: string }[] = connectivityCategoryTypes.map((connectivityType) => {
    return { text: connectivityLiteralsManager[connectivityType as DeviceConnectivityType], value: connectivityType };
  });

  const columns = ColumnsPeripheralDevices({
    columnSearchTranslations,
    searchInput,
    connectivityFilters,
    connectivityTagsManager,
    onSearch,
    onReset,
    onSelectSharedGateway,
  });

  const expandedColumns: TableColumnsType<DeviceSensorModel> = ExpandedPeripheralDevicesColumns(peripheralExpandedColumnsTranslations);

  const transformDevices = (data: DeviceModel[]): CustomDeviceModel[] => {
    return data.map((device) => {
      const gatewayId = !!device.gatewayUuid ? `:${device.gatewayUuid}` : '';
      const deviceId = `${device.deviceId}${gatewayId}`;
      const connectivity = !!device.connectivity ? device.connectivity : DeviceConnectivityType.UNKNOWN;
      const updatedAtLiteral = !!device.updatedAt
        ? moment.unix(device.updatedAt).format(DATE_TIME_FORMAT_REVERSE_SLASH)
        : t('not_reported');

      return { ...device, deviceId, connectivity, updatedAtLiteral };
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const devicesData = await GetDevicesLists({ host, token, projectId });
      const sharedDevices = devicesData.filter((device) => device.assetId === null);
      const transformedDevices = transformDevices(sharedDevices);
      setDevices(transformedDevices);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    devices,
    peripheralsTitle,
    searchedColumn,
    columns,
    expandedColumns,
    loading,
  };
};
