export enum LocalStorageAccessKeys {
  CLIENT_ID = 'ACCESS_CLIENT_ID',
  PROJECT_ID = 'ACCESS_PROJECT_ID',
  PROJECT_TYPE = 'ACCESS_PROJECT_TYPE',
  TAB = 'ACCESS_PROJECT_TAB',
}

export interface AccessKeys {
  clientId?: string;
  projectId?: string;
  projectType?: string;
  tab?: string;
}

export enum LocalStorageAccessCheckInKeys {
  NO_SHOW_AGAIN_CHECK_OUT_MODAL = 'ACCESS_CHECK_IN_NO_SHOW_CHECK_OUT',
}

export interface AccessCheckInKeys {
  checked?: boolean;
}

export interface AccessDeviceAssetPayload {
  asset_id: string;
  type: string;
  alias: string | null;
  city: string;
  province: string;
  country: string;
  street_type: string;
  street_name: string;
  block_number: string;
  zip_code: string;
  staircase: string | null;
  flat: string | null;
  door: string | null;
}

export interface AccessDevicePayload {
  asset: AccessDeviceAssetPayload | null;
  name: string | null;
  room: string | null;
  device_type: string | null;
  battery_level: number | null;
  shared: boolean;
  access_sensor_id: string;
}

export interface AccessDeviceMetaPayload {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface AccessDeviceLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface AccessDevicesListResponsePayload {
  data: AccessDevicePayload[];
  meta: AccessDeviceMetaPayload;
  links: AccessDeviceLinksPayload;
}

export interface AccessDeviceAssetModel {
  assetId: string;
  type: string;
  alias: string | null;
  city: string;
  province: string;
  country: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  zipCode: string;
  staircase: string | null;
  flat: string | null;
  door: string | null;
}

export interface AccessDeviceTypeModel {
  name: string;
  vendor: string;
  model: string;
}

export interface AccessDeviceModel {
  asset: AccessDeviceAssetModel | null;
  name: string | null;
  room: string | null;
  deviceType: AccessDeviceTypeModel | null;
  batteryLevel: number | null;
  shared: boolean;
  accessSensorId: string;
}

export interface AccessDeviceMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface AccessDeviceLinksModel {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface AccessDevicesListResponseModel {
  data: AccessDeviceModel[];
  meta: AccessDeviceMetaModel;
  links: AccessDeviceLinksModel;
}

export interface AccessDeviceSortModel {
  key: string;
  order: string;
}

export enum AccessDeviceTypeType {
  DOORLOCK_DANALOCK_V3 = 'DOORLOCK_DANALOCK_V3',
  DOORLOCK_ILOCK18 = 'DOORLOCK_ILOCK18',
  DOORLOCK_RAYONICS_ECYLINDER_EXCL = 'DOORLOCK_RAYONICS_ECYLINDER_EXCL',
  'DOORLOCK_TESA_SMARTair_i-max' = 'DOORLOCK_TESA_SMARTair_i-max',
  'DOORLOCK_VIANS_VI-BC10B' = 'DOORLOCK_VIANS_VI-BC10B',
  DOORSENSOR_AEOTEC_ZWA008 = 'DOORSENSOR_AEOTEC_ZWA008',
  DOORSENSOR_GOODWAY_TS2001Z1 = 'DOORSENSOR_GOODWAY_TS2001Z1',
  'DOORSENSOR_PHILIO_PST02-1C' = 'DOORSENSOR_PHILIO_PST02-1C',
  DOORSENSOR_TKB_TSM10 = 'DOORSENSOR_TKB_TSM10',
  'DOORSENSOR_ZIPATO_PH-PSM01.EU' = 'DOORSENSOR_ZIPATO_PH-PSM01.EU',
  DUMMY_LOCK = 'DUMMY_LOCK',
  DUMMY_SWITCH = 'DUMMY_SWITCH',
  GENERIC_COMMUNITY_DOOR = 'GENERIC_COMMUNITY_DOOR',
  GENERIC_LOCK = 'GENERIC_LOCK',
  GENERICSENSOR_AEOTEC_AERQ = 'GENERICSENSOR_AEOTEC_AERQ',
  GENERICSENSOR_HEIMAN_HS1HTZ = 'GENERICSENSOR_HEIMAN_HS1HTZ',
  INPUTSENSOR_SAYKEY_JTB300809 = 'INPUTSENSOR_SAYKEY_JTB300809',
  'PLUG_FIBARO_FGWPE/F-102' = 'PLUG_FIBARO_FGWPE/F-102',
  'PLUG_NEOCOOLCAM_NAS-WR01ZE' = 'PLUG_NEOCOOLCAM_NAS-WR01ZE',
  PLUG_QUBINO_ZMNHYD1 = 'PLUG_QUBINO_ZMNHYD1',
  'PLUG_SIMON_10002041-130' = 'PLUG_SIMON_10002041-130',
  PUSHBUTTON_AEON_ZW088 = 'PUSHBUTTON_AEON_ZW088',
  PUSHBUTTON_AEON_ZW130 = 'PUSHBUTTON_AEON_ZW130',
  'PUSHBUTTON_FIBARO_FGPB-101-2' = 'PUSHBUTTON_FIBARO_FGPB-101-2',
  'PUSHBUTTON_NEOCOOLCAM_NAS-RC01Z' = 'PUSHBUTTON_NEOCOOLCAM_NAS-RC01Z',
  SHELLY_SH1 = 'SHELLY_SH1',
  SHELLY_SH1PM = 'SHELLY_SH1PM',
  'SHELLY_SH2.5' = 'SHELLY_SH2.5',
  'SWITCH_AEON_ZW139-C' = 'SWITCH_AEON_ZW139-C',
  'SWITCH_FIBARO_FGBS-222' = 'SWITCH_FIBARO_FGBS-222',
  'SWITCH_FIBARO_FGS-213' = 'SWITCH_FIBARO_FGS-213',
  'SWITCH_FIBARO_FGS-214' = 'SWITCH_FIBARO_FGS-214',
  'SWITCH_FIBARO_FGS-222' = 'SWITCH_FIBARO_FGS-222',
  'SWITCH_FIBARO_FGS-223' = 'SWITCH_FIBARO_FGS-223',
  'SWITCH_FIBARO_FGS-224' = 'SWITCH_FIBARO_FGS-224',
  SWITCH_GOODWAY_TD13010Z1 = 'SWITCH_GOODWAY_TD13010Z1',
  SWITCH_PHILIO_PAN03 = 'SWITCH_PHILIO_PAN03',
  SWITCH_PHILIO_PAN04 = 'SWITCH_PHILIO_PAN04',
  SWITCH_PHILIO_PAN05 = 'SWITCH_PHILIO_PAN05',
  SWITCH_PHILIO_PAN06 = 'SWITCH_PHILIO_PAN06',
  SWITCH_QUBINO_ZMNHAD1 = 'SWITCH_QUBINO_ZMNHAD1',
  SWITCH_QUBINO_ZMNHBD1 = 'SWITCH_QUBINO_ZMNHBD1',
  SWITCH_QUBINO_ZMNHND1 = 'SWITCH_QUBINO_ZMNHND1',
  'SWITCH_SIMON_10002034-130' = 'SWITCH_SIMON_10002034-130',
  SWITCH_TKB_TZ74 = 'SWITCH_TKB_TZ74',
  SWITCH_TKB_TZ78 = 'SWITCH_TKB_TZ78',
  SWITCH_TKB_TZ79 = 'SWITCH_TKB_TZ79',
}

export enum AccessDeviceTypeVendor {
  AEON = 'AEON',
  AEOTEC = 'AEOTEC',
  ALFRED = 'ALFRED',
  DANALOCK = 'DANALOCK',
  FIBARO = 'FIBARO',
  GOODWAY = 'GOODWAY',
  HEIMAN = 'HEIMAN',
  ISURPASS = 'ISURPASS',
  NEOCOOLCAM = 'NEOCOOLCAM',
  PHILIO = 'PHILIO',
  QUBINO = 'QUBINO',
  RAYONICS = 'RAYONICS',
  SAYKEY = 'SAYKEY',
  SHELLY = 'SHELLY',
  SIMON = 'SIMON',
  TESA = 'TESA',
  TKB = 'TKB',
  VIANS = 'VIANS',
  ZIPATO = 'ZIPATO',
}

export interface ExternalInvitationsListMetaPayload {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface ExternalInvitationsListMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface ExternalInvitationsListLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export type ExternalInvitationsListLinksModel = ExternalInvitationsListLinksPayload;

export interface ExternalInvitationDataPayload {
  id: string;
  name: string;
  email: string;
  id_number: string | null;
  created_by: string;
  valid_until: number;
  valid_from: number;
  url: string;
  status: string;
}

export interface QRActiveModel {
  uid: string;
  qr: string;
  status: string;
  createdBy: string;
  validFrom: number;
  validUntil: number;
}

export interface QRActiveDataPayload {
  uid: string;
  qr: string;
  status: string;
  created_by: string;
  valid_from: number;
  valid_until: number;
}

export interface CreateQRActiveModel {
  qrCode: string;
  validFrom: number;
  validUntil: number;
}

export interface CreateQRActiveAtributesPayload {
  qr_code: string;
  allocations: { asset_id: string }[];
  valid_from: number;
  valid_until: number;
}

export interface CreateQRActivePayload {
  attributes: CreateQRActiveAtributesPayload;
}
