import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { CustomDeviceModel, DeviceWithoutGatewayModel, DeviceWithoutGatewayUpdateModel } from 'core/domain/devices/models';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import { useWaterMeterWm1000nb } from './useWaterMeterWm1000nb';
import { WaterMeterWm1000nbOptionsModel } from './WaterMeterWm1000nbForm';
import { DeviceTypesType } from './utils';
import { UpdateDeviceLiteralsType } from 'components/organisms/UpdateDeviceWithoutGatewayDrawer';
import { GetDeviceWithoutGateway } from 'core/domain/devices/repositories/getDeviceWithoutGateway';
import { UpdateDeviceDetailWithoutGateway } from 'core/domain/devices/repositories/updateDeviceWithoutGateway';

enum UpdateDeviceErrorCodeType {
  ALREADY_EXISTS = 400,
  NOT_FOUND = 404,
  SECRET_KEY_REQUIRED = 422,
}

export interface UpdateDeviceOptionsModel {
  updateDeviceLiterals: UpdateDeviceLiteralsType;
  waterMeterWm1000nbOptions: WaterMeterWm1000nbOptionsModel;
  formType: DeviceTypesType | null;
  onOpenUpdateDeviceDrawer: (device: CustomDeviceModel) => void;
  onCloseUpdateDeviceDrawer: () => void;
  onUpdateDevice: () => void;
  updateDeviceDrawerVisible: boolean;
  loading: boolean;
  updating: boolean;
}

export const useUpdateDeviceWithoutGateway = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [formType, setFormType] = useState<DeviceTypesType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [successUpdatingDevice, setSuccessUpdatingDevice] = useState<boolean>(false);
  const [device, setDevice] = useState<DeviceWithoutGatewayModel | null>(null);
  const {
    waterMeterWm1000nbOptions,
    waterMeterWm1000nbParameters,
    resetWaterMeterWm1000nbValues,
    getWaterMeterWm1000nbFormValues,
    errorCreatingWaterMeterWm1000nb,
  } = useWaterMeterWm1000nb();

  const literals: UpdateDeviceLiteralsType = {
    updateDeviceButtonText: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
    title: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_DRAWER_TITLE'),
    mainButtonText: t('update'),
    secondaryButtonText: t('cancel'),
  };

  const getDeviceData = async (deviceId: string) => {
    setLoading(true);
    try {
      const { data } = await GetDeviceWithoutGateway({ host, token, deviceId });
      setDevice(data);
    } catch (error) {
      setMessageError({ description: t('_GET_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const onOpenUpdateDeviceDrawer = (selectedDevice: CustomDeviceModel) => {
    const { deviceType, deviceId } = selectedDevice;
    setDrawerVisible(true);
    setFormType(deviceType as DeviceTypesType);
    getDeviceData(deviceId);
  };

  const resetValues = () => {
    setFormType(null);
    setUpdating(false);
    setDevice(null);
    resetWaterMeterWm1000nbValues();
  };

  const onCloseUpdateDeviceDrawer = () => {
    resetValues();
    setDrawerVisible(false);
  };

  const updateDeviceManager = {
    [DeviceTypesType.WATERMETER_WM1000NB]: () => waterMeterWm1000nbOptions.form.submit(),
  };

  const onUpdateDevice = () => {
    !!formType && updateDeviceManager[formType]();
    setUpdating(true);
  };

  const completeDeviceData = (newDevice: DeviceWithoutGatewayModel): DeviceWithoutGatewayUpdateModel => {
    const { assetId, configParameters, deviceType, name, room, secretKey, sensors, serialNumber } = newDevice;
    return {
      assetId,
      configParameters: !!configParameters ? configParameters : [],
      deviceType,
      name,
      room,
      secretKey,
      sensors,
      serialNumber,
    };
  };

  const controlledErrorDescriptionManager = {
    [UpdateDeviceErrorCodeType.ALREADY_EXISTS]: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_400_MESSAGE'),
    [UpdateDeviceErrorCodeType.NOT_FOUND]: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_404_MESSAGE'),
    [UpdateDeviceErrorCodeType.SECRET_KEY_REQUIRED]: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_422_MESSAGE'),
  };

  const checkErrors = (error: MessageErrorModel) => {
    const controlledErrors = Object.values(UpdateDeviceErrorCodeType);
    const isControlledError = controlledErrors.includes(error.code);
    const description = isControlledError
      ? controlledErrorDescriptionManager[error.code as UpdateDeviceErrorCodeType]
      : t('_UPDATE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');

    setMessageError({ description });
  };

  const updateDevice = async (newDevice: DeviceWithoutGatewayModel) => {
    try {
      const completedDeviceData = completeDeviceData(newDevice);
      await UpdateDeviceDetailWithoutGateway({ host, token, device: completedDeviceData, deviceId: newDevice.deviceUuid });
      setMessageSuccess({ description: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_SUCCESS_MESSAGE') });
      setSuccessUpdatingDevice(true);
      onCloseUpdateDeviceDrawer();
    } catch (error) {
      checkErrors(error as MessageErrorModel);
    } finally {
      setUpdating(false);
      setSuccessUpdatingDevice(false);
    }
  };

  const updateDeviceOptions: UpdateDeviceOptionsModel = {
    updateDeviceLiterals: literals,
    formType,
    waterMeterWm1000nbOptions,
    onOpenUpdateDeviceDrawer,
    onCloseUpdateDeviceDrawer,
    onUpdateDevice,
    updateDeviceDrawerVisible: drawerVisible,
    loading,
    updating,
  };

  useEffect(() => {
    !!waterMeterWm1000nbParameters && updateDevice(waterMeterWm1000nbParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterMeterWm1000nbParameters]);

  useEffect(() => {
    !!errorCreatingWaterMeterWm1000nb && setUpdating(false);
  }, [errorCreatingWaterMeterWm1000nb]);

  useEffect(() => {
    !!device && getWaterMeterWm1000nbFormValues(device);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  return { updateDeviceOptions, successUpdatingDevice };
};
