import { FC } from 'react';
import { Pagination } from 'antd';
import Paper from 'components/atoms/Paper';
import TableAtom from 'components/atoms/TableAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { CustomQrAccessModel } from '../resources/useQrAccessList';
import { AlfredTableColumn, DEFAULT_PAGE_SIZE } from 'components/organisms/AlfredTable';
import { TABLE_PAGE_SIZE_DEFAULT_OPTIONS } from 'constants/table';
import './styles.scss';

export interface QrAccessListTableProps {
  QrAccessListTableTemplateTitle: string;
  qrAccessList: CustomQrAccessModel[],
  qrAccessListColumns: AlfredTableColumn<CustomQrAccessModel>[];
  createQrAccessButtonText: string;
  qrAccessListCurrentPage: number;
  qrAccessListPageSize: number;
  qrAccessListTotalPages: number;
  onOpenCreateQrAccess: () => void;
  onChangeQrAccessListPagination: (page: number, pageSize?: number) => void;
  loading: boolean;
  isVisible: boolean;
}

export const QrAccessTableTemplate: FC<QrAccessListTableProps> = ({
  QrAccessListTableTemplateTitle,
  qrAccessList,
  qrAccessListColumns,
  createQrAccessButtonText,
  qrAccessListCurrentPage,
  qrAccessListPageSize,
  qrAccessListTotalPages,
  onOpenCreateQrAccess,
  onChangeQrAccessListPagination,
  loading,
}) => {

  return (
    <Paper>
      <div className={'QrAccessTableTemplate__header'}>
        <h2>{QrAccessListTableTemplateTitle}</h2>
        <DefaultButton
          key={'newQrAccess'}
          onClick={onOpenCreateQrAccess}
          title={createQrAccessButtonText}
        />
      </div>
      <TableAtom<CustomQrAccessModel>
        key={'uid'}
        loading={loading}
        dataSource={qrAccessList}
        columns={qrAccessListColumns}
        pagination={false}
        rowKey={'uid'}
        sticky
      />
      <Pagination
        className={'QrAccessTableTemplate__pagination'}
        size={'small'}
        showSizeChanger={true}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        pageSizeOptions={TABLE_PAGE_SIZE_DEFAULT_OPTIONS}
        current={qrAccessListCurrentPage}
        total={qrAccessListTotalPages}
        pageSize={qrAccessListPageSize}
        onChange={onChangeQrAccessListPagination}
      />
    </Paper>
  );
}