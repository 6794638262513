import { FC } from 'react';
import { TableColumnsType } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { DeviceModel, DeviceSensorModel } from 'core/domain/devices/models';
import TableAtom from 'components/atoms/TableAtom';

interface PeripheralDevicesProps {
  title: string;
  gatewayId?: string;
  devices?: DeviceModel[]
  loading?: boolean;
  columns: ColumnType<DeviceModel>[];
  expandedColumns: TableColumnsType<DeviceSensorModel>;
}

const PeripheralDevices: FC<PeripheralDevicesProps> = ({
  title,
  devices = [],
  loading = false,
  columns,
  expandedColumns,
}) => {

  const expandedRowRender = ({ sensors }: DeviceModel) => {
    return (
      <TableAtom<DeviceSensorModel>
        columns={expandedColumns}
        dataSource={sensors}
        pagination={false}
        rowKey={'sensorUuid'}
      />
    );
  };

  return (
    <>
      <h2>{title}</h2>
      <TableAtom<DeviceModel>
        className={'PeripheralDevices__expanded-table-wrapper'}
        loading={loading}
        columns={columns}
        expandable={{ expandedRowRender }}
        rowKey={'deviceId'}
        dataSource={devices}
        pagination={false}
      />
    </>
  );
};

export default PeripheralDevices;
