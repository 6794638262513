import { ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { InputRef } from 'antd';
import { ColumnType, FilterConfirmProps, SortOrder } from 'antd/lib/table/interface';
import { CustomDeviceModel } from 'constants/models';
import ContextualMenu from 'components/atoms/ContextualMenu';
import MenuAtom from 'components/atoms/MenuAtom';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import { DeviceConnectivityType } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useTabDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex, getColumnSearchProps } from '../SearchPeripheralColumns';
import TextAtom from 'components/atoms/TextAtom';
import './styles.scss';

export interface ColumnsPeripheralDevicesOptionsModel {
  gatewayId?: string;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  searchInput?: RefObject<InputRef>;
  connectivityFilters: { text: string, value: string }[];
  connectivityTagsManager: Record<DeviceConnectivityType, ReactNode>,
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onOpenMaintenanceActionsDrawer: (device: CustomDeviceModel) => void;
  onOpenUpdateDeviceDrawer: (device: CustomDeviceModel) => void;
  onOpenDeleteDeviceModal: (device: CustomDeviceModel) => void;
}

interface Props {
  options: ColumnsPeripheralDevicesOptionsModel;
}

interface SortPeripheralsByBatteryLevelProps {
  a: number | null,
  b: number | null,
  sortOrder?: SortOrder,
}

export const sortPeripherals = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortPeripheralsByBatteryLevel = ({ a, b, sortOrder }: SortPeripheralsByBatteryLevelProps) => {
  if (a === null) return sortOrder === 'ascend' ? 1 : -1;
  if (b === null) return sortOrder === 'ascend' ? -1 : 1;
  return a - b;
};

const sortVirtualGateways = (a: string | null, b: string | null) => {
  if (a !== null && b === null) return -1;
  if (a === null && b !== null) return 1;
  return 0;
}

export const ColumnsPeripheralDevices = ({ options }: Props): ColumnType<CustomDeviceModel>[] => {
  const { t } = useTranslation();
  const {
    columnSearchTranslations,
    searchInput,
    connectivityFilters,
    connectivityTagsManager,
    onSearch,
    onReset,
    onOpenMaintenanceActionsDrawer,
    onOpenUpdateDeviceDrawer,
    onOpenDeleteDeviceModal,
  } = options;

  return [
    {
      title: 'Id',
      key: 'deviceId',
      width: '5%',
      sorter: (a, b) => Number(a.deviceId) - Number(b.deviceId),
      sortDirections: ['ascend', 'descend'],
      dataIndex: 'deviceId',
    },
    {
      title: t('type'),
      key: 'deviceType',
      width: '15%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      sorter: (a, b) => sortPeripherals(a.deviceType, b.deviceType),
      ...getColumnSearchProps({ dataIndex: 'deviceType', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: t('name'),
      key: 'deviceName',
      width: '10%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'deviceName', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: t('remaining_battery'),
      key: 'batteryLevel',
      width: '15%',
      render: ({ batteryLevel }) => {
        return batteryLevel !== null
          ? <span>{`${batteryLevel}%`}</span>
          : <span className='Column__notReportedText'>{t('not_reported')}</span>
      },
      sorter: (a, b, sortOrder) => sortPeripheralsByBatteryLevel({ a: a.batteryLevel, b: b.batteryLevel, sortOrder }),
    },
    {
      title: `${t('_ASSET_DEVICE_COLUMN_CONNECTIVITY_TITLE')}`,
      key: 'connectivity',
      width: '10%',
      filters: connectivityFilters,
      onFilter: (value, { connectivity }) => !!connectivity && connectivity.includes(value as string),
      render: ({ connectivity }) => <span>{connectivityTagsManager[connectivity as DeviceConnectivityType]}</span>,
    },
    {
      title: `${t('_ASSET_DEVICE_COLUMN_UPDATED_AT_TITLE')}`,
      key: 'updatedAtLiteral',
      width: '10%',
      render: ({ updatedAt, updatedAtLiteral }) => <TextAtom disabled={!updatedAt}>{updatedAtLiteral}</TextAtom>,
    },
    {
      title: t('_DEVICES_COLUMNS_LINKED_REAL_GATEWAY_TITLE'),
      key: 'isVirtualGateway',
      width: '10%',
      render: ({ gatewayUuid }: { gatewayUuid: string | null }) => {
        return <span>{!!gatewayUuid ? t('_DEVICES_COLUMNS_LINKED_REAL_GATEWAY_TEXT') : t('_DEVICES_COLUMNS_HAS_NOT_LINKED_REAL_GATEWAY_TEXT')}</span>
      },
      sorter: (a, b) => sortVirtualGateways(a.gatewayUuid, b.gatewayUuid),
    },
    {
      title: t('actions'),
      key: 'menuExtra',
      width: '5%',
      render: (values: CustomDeviceModel) => {
        return <ContextualMenu menu={(
          <MenuAtom items={[
            {
              key: 'maintenanceActions',
              label: t('maintenance_actions'),
              disabled: !values.gatewayUuid,
              onClick: () => onOpenMaintenanceActionsDrawer(values),
            },
            !values.gatewayUuid ? {
              key: 'updateDevice',
              label: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
              onClick: () => onOpenUpdateDeviceDrawer(values),
            } : null,
            !values.gatewayUuid ? {
              key: 'deleteDevice',
              label: t('_DELETE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
              onClick: () => onOpenDeleteDeviceModal(values),
              danger: true,
            } : null,
          ]} />
        )} />
      },
    },
  ];
};
