import { Reducer, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Action, ManualQRAccessInitialStatesModel } from './models';
import { ManualQRAccessInputFormKey } from 'components/organisms/ManualQRAccessDrawer';
import { INITIAL_QR_CODE, INITIAL_RANGE, sendMessageError } from './utils';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import moment from 'moment';
import { CreateQRActiveByAssetId } from 'core/domain/access/repository/createQRActiveByAssetId';
import { CreateQRActiveModel } from 'core/domain/access/models';
import { RequestErrorModel } from 'core/utils/errors';

const mutualidad = {
  FLOOR_1: 'D5FJN2WBTXK93',
  FLOOR_2: 'DPNE4M27ATJ9A',
  FLOOR_3: 'DE2DJ4P8VGMB3',
  FLOOR_4: 'DRVTAK53J7F32',
  FLOOR_5: 'D6DEKCNQXT824',
  FLOOR_6: 'DWHV9MES4J73E',
  FLOOR_7: 'DJTR4S8BAYW55',
  FLOOR_8: 'D7KXNYH43S82E',
  GROUND_FLOOR: 'DSM9RG4KJLB77',
  BASEMENT: 'DSLGNP4XK96EA',
};

export const floorList = [
  mutualidad.FLOOR_1,
  mutualidad.FLOOR_2,
  mutualidad.FLOOR_3,
  mutualidad.FLOOR_4,
  mutualidad.FLOOR_5,
  mutualidad.FLOOR_6,
  mutualidad.FLOOR_7,
  mutualidad.FLOOR_8,
  mutualidad.GROUND_FLOOR,
  mutualidad.BASEMENT,
];

export const manualQRAccessInitialStates: ManualQRAccessInitialStatesModel = {
  [ManualQRAccessInputFormKey.RANGE_DATE]: INITIAL_RANGE,
  [ManualQRAccessInputFormKey.QR_CODE]: INITIAL_QR_CODE,
};

const reducer = (state: ManualQRAccessInitialStatesModel, action: Action) => ({ ...state, [action.key]: action.payload });

export const useManualQRAccess = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessCreatingQrAccess, setIsSuccessCreatingQrAccess] = useState<boolean>(false);
  const [{ QRCode, rangeDate }, dispatch] = useReducer<Reducer<ManualQRAccessInitialStatesModel, Action>>(
    reducer,
    manualQRAccessInitialStates
  );

  const titleText = t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_TITLE');
  const closeButtonText = t('_ACCESS_MANUAL_QR_ACCESS_BUTTON_TEXT_CLOSE');
  const saveButtonText = t('_ACCESS_MANUAL_QR_ACCESS_BUTTON_TEXT_SAVE');
  const initialQrBadgeText = t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_BADGE_INITIAL');

  const onUpdateManualQRAccess = () => {
    setIsVisible(true);
  };

  const onClose = () => {
    dispatch({
      key: ManualQRAccessInputFormKey.QR_CODE,
      payload: INITIAL_QR_CODE,
    });
    dispatch({
      key: ManualQRAccessInputFormKey.RANGE_DATE,
      payload: INITIAL_RANGE,
    });
    setIsVisible(false);
  };

  const onSaveQRCode = async () => {
    setIsLoading(true);
    const qrActiveData: CreateQRActiveModel = {
      qrCode: QRCode.value,
      validFrom: rangeDate.values[0].unix(),
      validUntil: rangeDate.values[1].unix(),
    };
    try {
      await CreateQRActiveByAssetId({ assetId, host, token, qrActiveData });
      onClose();
      setIsSuccessCreatingQrAccess(true);
    } catch (error) {
      const transformedError = error as RequestErrorModel;
      typeof transformedError.code === 'number' && sendMessageError(transformedError.code);
    } finally {
      setIsLoading(false);
      setIsSuccessCreatingQrAccess(false);
    }
  };

  const onSave = () => {
    const isQrCodeEmpty = !QRCode.value;
    const isRangeDateEmpty = !rangeDate.values.length;

    let isEmpty = isQrCodeEmpty && isRangeDateEmpty;

    if (isEmpty) {
      dispatch({
        key: ManualQRAccessInputFormKey.QR_CODE,
        payload: {
          ...QRCode,
          isError: true,
          errorMessage: t('_ACCESS_MANUAL_QR_ACCESS_ITEM_MESSAGE_ERROR_QR_CODE'),
        },
      });
      dispatch({
        key: ManualQRAccessInputFormKey.RANGE_DATE,
        payload: {
          ...rangeDate,
          isError: true,
          errorMessage: t('_ACCESS_MANUAL_QR_ACCESS_ITEM_MESSAGE_ERROR_RANGE'),
        },
      });
    }

    !isEmpty && onSaveQRCode();
  };

  const onChangeRange = (event: moment.Moment[]) => {
    dispatch({
      key: ManualQRAccessInputFormKey.RANGE_DATE,
      payload: {
        ...rangeDate,
        values: event,
      },
    });
  };

  const onChangeValue = (value: string) => {
    dispatch({
      key: ManualQRAccessInputFormKey.QR_CODE,
      payload: {
        ...QRCode,
        value,
      },
    });
  };

  rangeDate.onChangeRange = onChangeRange;
  QRCode.onChangeValue = onChangeValue;

  return {
    title: titleText,
    closeButtonText,
    saveButtonText,
    rangeDate,
    QRCode,
    initialQrBadgeText,
    onUpdateManualQRAccess,
    onClose,
    onSave,
    isVisible: isVisible,
    isLoading,
    isSuccessCreatingQrAccess,
  };
};
