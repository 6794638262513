import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { QrAccessListParams, QrAccessListResponsePayload } from '../models';
import { payloadToQrAccessListResponse } from '../mappers';

const getUrl = ({ host, assetId, page, size, search }: { host: string; assetId: string; page: number; size: number; search?: string }) => {
  const params = new URLSearchParams();
  page && params.append('page[number]', `${page}`);
  size && params.append('page[size]', `${size}`);
  !!search && params.append('search', search);

  return `${host}/qr/access/assets/${assetId}/list?${params}`;
};

export const GetQrAccessListByAssetIdWithPagination = async ({ host, token, assetId, page, size, search }: QrAccessListParams) => {
  try {
    const url = getUrl({ host, assetId, page, size, search });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<QrAccessListResponsePayload>;
    return payloadToQrAccessListResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
